/* stylelint-disable selector-type-no-unknown */

.hggs-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--app-background, var(--hggs-color-background-default));
  color: var(--app-color, var(--hggs-color-default));
  padding: var(--app-padding, var(--hggs-space-md-default) var(--hggs-space-default));
  font-size: var(--app-font-size, var(---hggs-font-size-root-default));
  font-family: var(--hggs-font-family, var(--hggs-font-family-default));

  &.hggs-app--left {
    align-items: flex-start;
  }

  &.hggs-app--transition {
    view-transition-name: app;
  }

  /* TODO: Extract all of this into a container component */
  & > header {
    & > .hggs-h1:first-child {
      margin-top: 0;
    }
  }

  & > main {
    flex: 1;
  }

  & > footer {
    padding-top: var(--app-footer-padding-top, 0);
  }

  & > header,
  & > main,
  & > footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
}

/* stylelint-disable-next-line selector-pseudo-element-no-unknown */
::view-transition-old(app) {
  animation: fade 0.2s linear forwards;
}

/* stylelint-disable-next-line selector-pseudo-element-no-unknown */
::view-transition-new(app) {
  animation: fade 0.3s linear reverse;
}

@keyframes fade {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}
