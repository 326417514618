.hggs-button {
  border: unset;
  text-align: center;
  padding: var(--button-padding-medium, calc(var(--hggs-space-sm-default)) var(--hggs-space-default));
  margin:
    var(--button-margin-vertical, var(--hggs-space-default))
    var(--button-margin-horizontal, var(--hggs-space-xs-default));
  font-size: var(--button-font-size-medium, var(--hggs-font-size-default));
  font-family: var(--button-font-family, var(--hggs-font-family));
  font-weight: var(--button-font-weight, var(--hggs-font-weight-default));
  color: var(--button-color, var(--hggs-color-quinary-default));
  letter-spacing: var(--button-font-letter-spacing);
  border-radius: var(--button-border-radius);
  cursor: var(--button-cursor, default);
  background-color: var(--button-background, var(--hggs-color-default));

  &:hover {
    background-color: var(--button-background-hover, var(--hggs-color-default));
    box-shadow: var(--button-box-shadow-hover, var(--hggs-shadow-default));
    transition:
      var(
        --button-transition-hover,
        width var(--hggs-animation-ease-default),
        height var(--hggs-animation-ease-default)
      );
    transform: var(--button-transform-hover, var(--hggs-transform-escale-default));
    color: var(--button-color-hover, var(--hggs-color-quinary-default));
  }

  &:focus {
    outline: var(--button-outline-focus, none);
    box-shadow: var(--button-box-shadow-focus, var(--hggs-shadow-primary-default));
  }

  &.hggs-button--primary {
    color: var(--button-color-primary, var(--hggs-color-quinary-default));
    background-color: var(--button-background-primary, var(--hggs-color-primary-default));
  }

  &.hggs-button--primary,
  &.hggs-icon--primary {
    &:hover {
      background-color: var(--button-background-primary-hover, var(--hggs-color-primary-hover-default));
      box-shadow: var(--button-box-shadow-primary-hover, var(--hggs-shadow-default));
      transition:
        var(
          --button-transition-primary-hover,
          width var(--hggs-animation-ease-default),
          height var(--hggs-animation-ease-default)
        );
      transform: var(--button-transform-primary-hover, var(--hggs-transform-escale-default));
      color: var(--button-color-primary, var(--hggs-color-quinary-default));
    }

    &:focus {
      box-shadow: var(--button-box-shadow-primary-focus, --hggs-form-box-primary-focus-default);
    }
  }

  &.hggs-button--secondary {
    color: var(--button-color-secondary, var(--hggs-color-quinary-default));
    background-color: var(--button-background-secondary, var(--hggs-color-secondary-default));
  }

  &.hggs-button--secondary,
  &.hggs-icon--secondary {
    &:hover {
      background-color: var(--button-background-secondary-hover, var(--hggs-color-secondary-hover-default));
      box-shadow: var(--button-box-shadow-secondary-hover, var(--hggs-shadow-default));
      transition:
        var(
          --button-transition-secondary-hover,
          width var(--hggs-animation-ease-default),
          height var(--hggs-animation-ease-default)
        );
      transform: var(--button-transform-secondary-hover, var(--hggs-transform-escale-default));
      color: var(--button-color-secondary, var(--hggs-color-quinary-default));
    }

    &:focus {
      box-shadow: var(--button-box-shadow-secondary-focus, --hggs-form-box-shadow-secondary-focus-default);
    }
  }

  &.hggs-button--tertiary {
    color: var(--button-color-tertiary, var(--hggs-color-quinary-default));
    background-color: var(--button-background-tertiary, var(--hggs-color-tertiary-default));
  }

  &.hggs-button--tertiary,
  &.hggs-icon--tertiary {
    &:hover {
      background-color: var(--button-background-tertiary-hover, var(--hggs-color-tertiary-hover-default));
      box-shadow: var(--button-box-shadow-tertiary-hover, var(--hggs-shadow-default));
      transition:
        var(
          --button-transition-tertiary-hover,
          width var(--hggs-animation-ease-default),
          height var(--hggs-animation-ease-default)
        );
      color: var(--button-color-tertiary, var(--hggs-color-quinary-default));
    }

    &:focus {
      box-shadow: var(--button-box-shadow-tertiary-focus, --hggs-form-box-shadow-tertiary-focus-default);
    }
  }

  &.hggs-button--quaternary {
    color: var(--button-color-quaternary, var(--hggs-color-quinary-default));
    background-color: var(--button-background-quaternary, var(--hggs-color-quaternary-default));
  }

  &.hggs-button--quaternary,
  &.hggs-icon--quaternary {
    &:hover {
      background-color: var(--button-background-quaternary-hover, var(--hggs-color-quaternary-hover-default));
      box-shadow: var(--button-box-shadow-quaternary-hover, var(--hggs-shadow-default));
      transition:
        var(
          --button-transition-quaternary-hover,
          width var(--hggs-animation-ease-default),
          height var(--hggs-animation-ease-default)
        );
      color: var(--button-color-quaternary, var(--hggs-color-quinary-default));
    }

    &:focus {
      box-shadow: var(--button-box-shadow-quaternary-focus, --hggs-form-box-shadow-quaternary-focus-default);
    }
  }

  &.hggs-button--quinary {
    color: var(--button-color-quinary, var(--hggs-color-quaternary-default));
    background-color: var(--button-background-quinary, var(--hggs-color-quinary-default));
  }

  &.hggs-button--quinary,
  &.hggs-icon--quinary {
    &:hover {
      background-color: var(--button-background-quinary-hover, var(--hggs-color-quinary-hover-default));
      box-shadow: var(--button-box-shadow-quinary-hover, var(--hggs-shadow-default));
      transition:
        var(
          --button-transition-quinary-hover,
          width var(--hggs-animation-ease-default),
          height var(--hggs-animation-ease-default)
        );
      color: var(--button-color-quinary, var(--hggs-color-quaternary-default));
    }

    &:focus {
      box-shadow: var(--button-box-shadow-quinary-focus, --hggs-form-box-shadow-quinary-focus-default);
    }
  }

  &.hggs-button--senary {
    color: var(--button-color-senary, var(--hggs-color-quinary-default));
    background-color: var(--button-background-senary, var(--hggs-color-senary-default));
  }

  &.hggs-button--senary,
  &.hggs-icon--senary {
    &:hover {
      background-color: var(--button-background-senary-hover, var(--hggs-color-senary-hover-default));
      box-shadow: var(--button-box-shadow-senary-hover, var(--hggs-shadow-default));
      transition:
        var(
          --button-transition-senary-hover,
          width var(--hggs-animation-ease-default),
          height var(--hggs-animation-ease-default)
        );
      color: var(--button-color-senary, var(--hggs-color-quinary-default));
    }

    &:focus {
      box-shadow: var(--button-box-shadow-senary-focus, --hggs-form-box-shadow-senary-focus-default);
    }
  }

  &:has(.hggs-icon):not(.hggs-icon-wrapper) {
    background: unset;
    margin: 0;
    padding: 0;

    &:focus {
      box-shadow: unset;
    }
  }

  &.hggs-button--outline {
    background-color: transparent;
    color: var(--button-color-outline, var(--hggs-color-default));
    border: var(--button-border-outline, var(--hggs-border-default));

    &:hover {
      background-color: transparent;
      outline: unset;
      outline-offset: 0;
      box-shadow: var(--button-box-shadow-outline-hover, unset);
      color: var(--button-color-outline-hover, var(--hggs-color-default));
      border: var(--button-border-outline-hover, var(--hggs-border-default));
    }

    &.hggs-button--primary {
      background-color: transparent;
      color: var(--button-color-outline-primary, var(--hggs-color-primary-default));
      border: var(--button-border-outline-primary, var(--hggs-border-primary-default));

      &:hover {
        background-color: transparent;
        outline: unset;
        outline-offset: 0;
        box-shadow: var(--button-box-shadow-outline-primary-hover, unset);
        color: var(--button-color-outline-primary-hover, var(--hggs-color-default));
        border: var(--button-border-outline-primary-hover, var(--hggs-border-default));
      }
    }

    &.hggs-button--secondary {
      background-color: transparent;
      color: var(--button-color-outline-secondary, var(--hggs-color-quaternary-default));
      border: var(--button-border-outline-secondary, var(--hggs-border-secondary-default));

      &:hover {
        background-color: transparent;
        outline: unset;
        outline-offset: 0;
        box-shadow: var(--button-box-shadow-outline-secondary-hover, unset);
        color: var(--button-color-outline-secondary-hover, var(--hggs-color-primary-default));
        border: var(--button-border-outline-secondary-hover, var(--hggs-border-primary-default));
      }
    }

    &.hggs-button--tertiary {
      background-color: transparent;
      color: var(--button-color-outline-tertiary, var(--hggs-color-tertiary-default));
      border: var(--button-border-outline-tertiary, var(--hggs-border-tertiary-default));

      &:hover {
        background-color: transparent;
        outline: unset;
        outline-offset: 0;
        box-shadow: var(--button-box-shadow-outline-tertiary-hover, unset);
        color: var(--button-color-outline-tertiary-hover, var(--hggs-color-secondary-default));
        border: var(--button-border-outline-tertiary-hover, var(--hggs-border-secondary-default));
      }
    }

    &.hggs-button--quaternary {
      background-color: transparent;
      color: var(--button-color-outline-quaternary, var(--hggs-color-quaternary-default));
      border: var(--button-border-outline-quaternary, var(--hggs-border-quaternary-default));

      &:hover {
        background-color: transparent;
        outline: unset;
        outline-offset: 0;
        box-shadow: var(--button-box-shadow-outline-quaternary-hover, unset);
        color: var(--button-color-outline-quaternary-hover, var(--hggs-color-quaternary-default));
        border: var(--button-border-outline-quaternary-hover, var(--hggs-border-quaternary-default));
      }
    }

    &.hggs-button--quinary {
      background-color: transparent;
      color: var(--button-color-outline-quinary, var(--hggs-color-quinary-default));
      border: var(--button-border-outline-quinary, var(--hggs-border-quinary-default));

      &:hover {
        background-color: transparent;
        outline: unset;
        outline-offset: 0;
        box-shadow: var(--button-box-shadow-outline-quinary-hover, unset);
        color: var(--button-color-outline-quinary-hover, var(--hggs-color-quinary-default));
        border: var(--button-border-outline-quinary-hover, var(--hggs-border-quinary-default));
      }
    }

    &.hggs-button--senary {
      background-color: transparent;
      color: var(--button-color-outline-senary, var(--hggs-color-senary-default));
      border: var(--button-border-outline-senary, var(--hggs-border-senary-default));

      &:hover {
        background-color: transparent;
        outline: unset;
        outline-offset: 0;
        box-shadow: var(--button-box-shadow-outline-senary-hover, unset);
        color: var(--button-color-outline-senary-hover, var(--hggs-color-tertiary-default));
        border: var(--button-border-outline-senary-hover, var(--hggs-border-tertiary-default));
      }
    }
  }

  &.hggs-button--full-width {
    width: 100%;

    &:hover {
      transform: var(--button-transform-full-width-hover, --hggs-transform-escale-small-default);
    }
  }

  &.hggs-button--big {
    padding: var(--button-padding-big, calc(var(--hggs-space-default) * 1.5) var(--hggs-space-lg-default));
    font-size: var(--button-font-size-big, var(--hggs-font-size-2xl-default));
  }

  &.hggs-button--medium {
    padding: var(--button-padding-medium, calc(var(--hggs-space-default) / 1.3) var(--hggs-space-md-default));
    font-size: var(--button-font-size-medium, var(--hggs-font-size-lg-default));
  }

  &.hggs-button--small:not(.hggs-button--circle, .hggs-button--square) {
    padding: var(--button-padding-small, var(--hggs-space-xs-default) var(--hggs-space-sm-default));
    font-size: var(--button-font-size-small, var(--hggs-font-size-xs-default));
  }
}
