.hggs-icon {
  display: block;
  width: var(--icon-size, var(--hggs-icon-size-default));
  height: var(--icon-size, var(--hggs-icon-size-default));
  margin: var(--icon-margin, var(--hggs-space-xs-default));
  filter: var(--icon-filter, var(--hggs-filter-default));

  &.hggs-icon--small {
    width: var(--icon-size-small, var(--hggs-icon-size-small-default));
    height: var(--icon-size-small, var(--hggs-icon-size-small-default));
    margin: var(--icon-margin-small, var(--hggs-space-2xs-default));
  }

  &.hggs-icon--micro {
    width: var(--icon-size-micro, var(--hggs-icon-size-micro-default));
    height: var(--icon-size-micro, var(--hggs-icon-size-micro-default));
    margin: var(--icon-margin-micro, var(--hggs-space-3xs-default));
  }

  &.hggs-icon--user {
    background: var(--icon-user, var(--hggs-icon-user-default)) no-repeat center;
  }

  &.hggs-icon--question {
    background: var(--icon-question, var(--hggs-icon-question-default)) no-repeat center;
  }

  &.hggs-icon--log-out {
    background: var(--icon-log-out, var(--hggs-icon-log-out-default)) no-repeat center;
  }

  &.hggs-icon--hamburger-menu {
    background: var(--icon-hamburger-menu, var(--hggs-icon-hamburger-menu-default)) no-repeat center;
  }

  &.hggs-icon--edit {
    background: var(--icon-edit, var(--hggs-icon-edit-default)) no-repeat center;
  }

  &.hggs-icon--close {
    background: var(--icon-close, var(--hggs-icon-close-default)) no-repeat center;
  }

  &.hggs-icon--plus {
    background: var(--icon-plus, var(--hggs-icon-plus-default)) no-repeat center;
  }

  &.hggs-icon--chevron-down {
    background: var(--icon-chevron-down, var(--hggs-icon-chevron-down-default)) no-repeat center;
  }

  &.hggs-icon--check {
    background: var(--icon-check, var(--hggs-icon-check-default)) no-repeat center;
  }

  &.hggs-icon--pending {
    background: var(--icon-pending, var(--hggs-icon-pending-default)) no-repeat center;
  }

  &.hggs-icon--primary {
    filter: var(--icon-filter-primary, var(--hggs-filter-primary-default));
  }

  &.hggs-icon--secondary {
    filter: var(--icon-filter-secondary, var(--hggs-filter-secondary-default));
  }

  &.hggs-icon--tertiary {
    filter: var(--icon-filter-tertiary, var(--hggs-filter-tertiary-default));
  }

  &.hggs-icon--quaternary {
    filter: var(--icon-filter-quaternary, var(--hggs-filter-quaternary-default));
  }

  &.hggs-icon--quinary {
    filter: var(--icon-filter-quinary, var(--hggs-filter-quinary-default));
  }

  &.hggs-icon--senary {
    filter: var(--icon-filter-senary, var(--hggs-filter-senary-default));
  }
}

.hggs-icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: var(--icon-margin, var(--hggs-space-xs-default));

  & .hggs-icon {
    margin: 0;
  }

  & .hggs-icon + .hggs-icon-label {
    flex: 1;
    font-family: var(--icon-label-font-family, var(--hggs-font-family-default));
    font-weight: var(--icon-label-font-weigth, var(--hggs-font-weight-md-default));
    font-size: var(--icon-label-font-size, var(--hggs-font-size-default));
    color: var(--icon-label-color, var(--hggs-color-quaternary-default));
    text-align: var(--icon-label-text-align, left);
    margin-left: var(--icon-label-margin-left, var(--hggs-space-sm-default));
    margin-top: auto;
    margin-bottom: auto;
  }

  & .hggs-icon.hggs-icon--quinary + .hggs-icon-label {
    color: var(--icon-label-quinary-color, var(--hggs-color-quinary-default));
  }

  &.hggs-icon--circle,
  &.hggs-icon--square {
    width: auto;
    height: auto;
    padding: var(--icon-box-padding, var(--hggs-space-default));

    & .hggs-icon {
      filter: var(--icon-box-filter, var(--hggs-filter-quinary-default));
    }

    &.hggs-icon--small {
      padding: var(--icon-box-padding-small, calc(var(--hggs-space-sm-default) * 1.25));

      & .hggs-icon {
        width: var(--icon-size-small, var(--hggs-icon-size-small-default));
        height: var(--icon-size-small, var(--hggs-icon-size-small-default));
      }
    }

    &.hggs-icon--micro {
      padding: var(--icon-box-padding-micro, calc(var(--hggs-space-xs-default) * 1.5));

      & .hggs-icon {
        width: var(--icon-size-micro, var(--hggs-icon-size-micro-default));
        height: var(--icon-size-micro, var(--hggs-icon-size-micro-default));
      }
    }

    &.hggs-icon--primary {
      background: var(--icon-box-background-primary, var(--hggs-color-primary-default));

      & .hggs-icon {
        filter: var(--icon-box-filter-primary, var(--hggs-filter-quinary-default));
      }
    }

    &.hggs-icon--secondary {
      background: var(--icon-box-background-secondary, var(--hggs-color-secondary-default));

      & .hggs-icon {
        filter: var(--icon-box-filter-secondary, var(--hggs-filter-quinary-default));
      }
    }

    &.hggs-icon--tertiary {
      background: var(--icon-box-background-tertiary, var(--hggs-color-tertiary-default));

      & .hggs-icon {
        filter: var(--icon-box-filter-tertiary, var(--hggs-filter-quinary-default));
      }
    }

    &.hggs-icon--quaternary {
      background: var(--icon-box-background-quaternary, var(--hggs-color-quaternary-default));

      & .hggs-icon {
        filter: var(--icon-box-filter-quaternary, var(--hggs-filter-quinary-default));
      }
    }

    &.hggs-icon--quinary {
      background: var(--icon-box-background-quinary, var(--hggs-color-quinary-default));

      & .hggs-icon {
        filter: var(--icon-box-filter-quinary var(--hggs-filter-quaternary-default));
      }
    }

    &.hggs-icon--senary {
      background: var(--icon-box-background-senary, var(--hggs-color-senary-default));

      & .hggs-icon {
        filter: var(--icon-box-filter-senary, var(--hggs-filter-quinary-default));
      }
    }

    &.hggs-icon--outline {
      background: transparent;
      border: var(--icon-outline-border, var(--hggs-border-primary-default));

      & .hggs-icon {
        filter: var(--icon-filter, var(--hggs-filter-quaternary-default));
      }

      &.hggs-icon--primary {
        background: transparent;
        border: var(--icon-outline-border-primary, var(--hggs-border-primary-default));

        & .hggs-icon {
          filter: var(--icon-filter-primary, var(--hggs-filter-primary-default));
        }
      }

      &.hggs-icon--secondary {
        background: transparent;
        border: var(--icon-outline-border-secondary, var(--hggs-border-secondary-default));

        & .hggs-icon {
          filter: var(--icon-filter-secondary, var(--hggs-filter-secondary-default));
        }
      }

      &.hggs-icon--tertiary {
        background: transparent;
        border: var(--icon-outline-border-tertiary, var(--hggs-border-tertiary-default));

        & .hggs-icon {
          filter: var(--icon-filter-tertiary, var(--hggs-filter-tertiary-default));
        }
      }

      &.hggs-icon--quaternary {
        background: transparent;
        border: var(--icon-outline-border-quaternary, var(--hggs-border-quaternary-default));

        & .hggs-icon {
          filter: var(--icon-filter-quaternary, var(--hggs-filter-quaternary-default));
        }
      }

      &.hggs-icon--quinary {
        background: transparent;
        border: var(--icon-outline-border-quinary, var(--hggs-border-quinary-default));

        & .hggs-icon {
          filter: var(--icon-filter-quinary, var(--hggs-filter-quinary-default));
        }
      }

      &.hggs-icon--senary {
        background: transparent;
        border: var(--icon-outline-border-senary, var(--hggs-border-senary-default));

        & .hggs-icon {
          filter: var(--icon-filter-senary, var(--hggs-filter-senary-default));
        }
      }
    }
  }

  &.hggs-icon--circle {
    background: var(--icon-box-background, var(--hggs-color-quaternary-default));
    border: unset;
    border-radius: 50%;
  }

  &.hggs-icon--square {
    background: var(--icon-box-background, var(--hggs-color-quaternary-default));
    border: unset;
    border-radius: 0;
  }
}
