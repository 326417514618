.hggs-flex {
  display: flex;

  &.hggs-flex--column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.hggs-flex--column-top-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &.hggs-flex--column-top-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.hggs-flex--column-top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &.hggs-flex--row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &.hggs-flex--row-top-center {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  &.hggs-flex--row-left-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  &.hggs-flex--row-right-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &.hggs-flex--row-left-stretch {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }

  &.hggs-flex--row-top-left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.hggs-flex--row-space-between-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &.hggs-flex--column-center,
  &.hggs-flex--column-top-center,
  &.hggs-flex--column-top-left,
  &.hggs-flex--column-top-right,
  &.hggs-flex--row-center,
  &.hggs-flex--row-top-center,
  &.hggs-flex--row-left-center,
  &.hggs-flex--row-left-stretch,
  &.hggs-flex--row-top-left,
  &.hggs-flex--row-space-between-center {
    &.hggs-flex-item--flex-1 {
      flex: 1;
    }

    &.hggs-flex--row-wrap {
      flex-wrap: wrap;
    }
  }

  &.hggs-flex--column-center,
  &.hggs-flex--column-top-center,
  &.hggs-flex--column-top-left,
  &.hggs-flex--column-top-right {
    &.hggs-flex--gap-small {
      gap: var(--hggs-flex-column-gap-small, var(--hggs-space-sm-default));
    }

    &.hggs-flex--gap-medium {
      gap: var(--hggs-flex-column-gap-medium, var(--hggs-space-default));
    }

    &.hggs-flex--gap-big {
      gap: var(--hggs-flex-column-gap-big, var(--hggs-space-md-default));
    }
  }

  &.hggs-flex--row-center,
  &.hggs-flex--row-top-center,
  &.hggs-flex--row-left-center,
  &.hggs-flex--row-right-center,
  &.hggs-flex--row-left-stretch,
  &.hggs-flex--row-top-left,
  &.hggs-flex--row-space-between-center {
    &.hggs-flex--gap-small {
      gap: var(--hggs-flex-row-gap-small, var(--hggs-space-sm-default));
    }

    &.hggs-flex--gap-medium {
      gap: var(--hggs-flex-row-gap-medium, var(--hggs-space-default));
    }

    &.hggs-flex--gap-big {
      gap: var(--hggs-flex-row-gap-big, var(--hggs-space-md-default));
    }
  }
}
