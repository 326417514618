@keyframes hggs-dialog-appear-default {
  from {
    opacity: 0;
    transform: scale(0.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.hggs-dialog {
  flex-direction: column;
  position: relative;
  background-color: var(--dialog-background-color, var(--hggs-color-background-default));
  color: var(--dialog-color, var(--hggs-color-default));
  text-align: var(--dialog-text-align, left);
  border: none;
  padding: var(--dialog-padding, var(--hggs-space-default));
  border-radius: var(--dialog-border-radius, var(--hggs-border-default));
  box-shadow: var(--dialog-background-box-shadow, none);
  max-width: var(--dialog-max-width, 100vw);

  & > .hggs-dialog-header {
    & > .hggs-h3,
    & > .hggs-h4,
    & > .hggs-h5,
    & > .hggs-h6 {
      margin-top: 0;
    }

    & .hggs-dialog-close {
      position: var(--dialog-close-position, absolute);
      top: var(--dialog-close-top, 0);
      right: var(--dialog-close-right, 1px);
    }
  }

  & > .hggs-dialog-body {
    flex: 1;
  }

  & > .hggs-dialog-footer {
    & > .hggs-dialog-actions {
      & > .hggs-button {
        margin-bottom: 0;
      }
    }
  }

  &.hggs-dialog--big {
    width: var(--dialog-width-big, 90vw);
  }

  &.hggs-dialog--medium {
    width: var(--dialog-width-medium, 75vw);
  }

  &.hggs-dialog--small {
    width: var(--dialog-width-small, 55vw);
  }

  &[open] {
    display: flex;
    animation: var(--dialog-anmimation-open, hggs-dialog-appear-default 0.15s);
  }

  &::backdrop {
    background: linear-gradient(45deg, rgb(58 58 58 / 60%), rgb(8 8 8 / 60%));
  }
}
