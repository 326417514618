.hggs-text {
  color: var(--text-color, var(--hggs-font-color-default));
  font-family: var(--text-font-family, var(--hggs-font-famiy-default));
  font-size: var(--text-font-size, var(--hggs-font-size-default));
  font-weight: var(--text-font-weight, var(--text-font-weight-default));
  margin: var(--text-margin, var(--hggs-space-default) 0);
  line-height: var(--text-line-height, var(--hggs-line-height-default));
  text-align: var(--text-align, left);

  &.hggs-text--bold,
  & .hggs-text--bold {
    font-weight: var(--text-font-weight-bold, var(--hggs-font-weight-lg-default));
  }

  &.hggs-text--italic,
  & .hggs-text--italic {
    font-style: italic;
  }

  &.hggs-text--small {
    font-size: var(--text-font-size-small, var(--hggs-font-size-sm-default));
    line-height: var(--text-line-height-small, var(--hggs-line-height-sm-default));
  }

  &.hggs-text--align-left {
    text-align: left;
  }

  &.hggs-text--align-center {
    text-align: center;
  }

  &.hggs-text--align-right {
    text-align: right;
  }

  &.hggs-text--balanced {
    text-wrap: balance;
  }
}
