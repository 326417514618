.hggs-tag {
  width: fit-content;
  font-family: var(--tag-font-family, var(--hggs-font-family-default));
  font-size: var(--tag-font-size, var(--hggs-font-size-sm-default));
  font-weight: var(--tag-font-weight, var(--hgss-font-weight-default));
  background: var(--tag-background, var(--hggs-color-quaternary-default));
  color: var(--tag-color, var(--hggs-color-quinary-default));
  padding: var(--tag-padding, var(--hggs-space-sm-default) var(--hggs-space-md-default));
  text-align: var(--tag-text-align, center);
  text-transform: var(--tag-text-transform, uppercase);
  letter-spacing: var(--tag-letter-spacing, var(--hggs-font-letter-spacing-sm));

  /* reset tag background in outlines */
  &.hggs-tag--outline,
  &.hggs-tag--subtle.hggs-tag--outline,
  &.hggs-tag--primary.hggs-tag--outline,
  &.hggs-tag--primary.hggs-tag--subtle.hggs-tag--outline,
  &.hggs-tag--secondary.hggs-tag--outline,
  &.hggs-tag--secondary.hggs-tag--subtle.hggs-tag--outline,
  &.hggs-tag--tertiary.hggs-tag--outline,
  &.hggs-tag--tertiary.hggs-tag--subtle.hggs-tag--outline,
  &.hggs-tag--quaternary.hggs-tag--outline,
  &.hggs-tag--quaternary.hggs-tag--subtle.hggs-tag--outline,
  &.hggs-tag--quinary.hggs-tag--outline,
  &.hggs-tag--quinary.hggs-tag--subtle.hggs-tag--outline,
  &.hggs-tag--senary.hggs-tag--outline,
  &.hggs-tag--senary.hggs-tag--subtle.hggs-tag--outline {
    background: unset;
    background-image: unset;
  }

  &.hggs-tag--outline {
    border: var(--tag-border-outline, var(--hggs-border-default));
    color: var(--tag-color-outline, var(--hggs-color-default));
  }

  &.hggs-tag--subtle {
    background: var(--tag-background-subtle, var(--hggs-color-gray-015-default));
    color: var(--tag-color-subtle, var(--hggs-color-quinary-default));

    &.hggs-tag--outline {
      border: var(--tag-border-outline-subtle, var(--hggs-border-auxiliary-gray-015-default));
      color: var(--tag-color-outline-subtle, var(--hggs-color-gray-015-default));
    }
  }

  &.hggs-tag--primary {
    background: var(--tag-background-primary, var(--hggs-color-primary-default));
    color: var(--tag-color-primary, var(--hggs-color-quinary-default));

    &.hggs-tag--outline {
      border: var(--tag-border-outline-primary, var(--hggs-border-primary-default));
      color: var(--tag-color-outline-primary, var(--hggs-color-primary-default));
    }

    &.hggs-tag--subtle {
      background: var(--tag-background-subtle-primary, var(--hggs-color-gray-013-default));
      color: var(--tag-color-subtle-primary, var(--hggs-color-quinary-default));

      &.hggs-tag--outline {
        border: var(--tag-border-outline-primary-subtle, var(--hggs-border-auxiliary-gray-013-default));
        color: var(--tag-color-outline-primary-subtle, var(--hggs-color-gray-013-default));
      }
    }
  }

  &.hggs-tag--secondary {
    background: var(--tag-background-secondary, var(--hggs-color-secondary-default));
    color: var(--tag-color-secondary, var(--hggs-color-quinary-default));

    &.hggs-tag--outline {
      border: var(--tag-border-outline-secondary, var(--hggs-border-secondary-default));
      color: var(--tag-color-outline-secondary);
    }

    &.hggs-tag--subtle {
      background: var(--tag-background-subtle-secondary, var(--hggs-color-gray-012-default));
      color: var(--tag-color-subtle-secondary, var(--hggs-color-quinary-default));

      &.hggs-tag--outline {
        border: var(--tag-border-outline-secondary-subtle, var(--hggs-border-auxiliary-gray-012-default));
        color: var(--tag-color-outline-secondary-subtle, var(--hggs-color-gray-012-default));
      }
    }
  }

  &.hggs-tag--tertiary {
    background: var(--tag-background-tertiary, var(--hggs-color-secondary-default));
    color: var(--tag-color-tertiary, var(--hggs-color-quinary-default));

    &.hggs-tag--outline {
      border: var(--tag-border-outline-tertiary, var(--hggs-border-tertiary-default));
      color: var(--tag-color-outline-tertiary, var(--hggs-color-tertiary-default));
    }

    &.hggs-tag--subtle {
      background: var(--tag-background-subtle-tertiary, var(--hggs-color-gray-010-default));
      color: var(--tag-color-subtle-tertiary, var(--hggs-color-quinary-default));

      &.hggs-tag--outline {
        border: var(--tag-border-outline-tertiary-subtle, var(--hggs-border-auxiliary-gray-010-default));
        color: var(--tag-color-outline-tertiary-subtle, var(--hggs-color-gray-010-default));
      }
    }
  }

  &.hggs-tag--quaternary {
    background: var(--tag-background-quaternary, var(--hggs-color-quaternary-default));
    color: var(--tag-color-quaternary, var(--hggs-color-quinary-default));

    &.hggs-tag--outline {
      border: var(--tag-border-outline-quaternary, var(--hggs-border-quaternary-default));
      color: var(--tag-color-outline-quaternary, var(--hggs-color-quaternary-default));
    }

    &.hggs-tag--subtle {
      background: var(--tag-background-subtle-quaternary, var(--hggs-color-gray-009-default));
      color: var(--tag-color-subtle-quaternary, var(--hggs-color-quaternary-default));

      &.hggs-tag--outline {
        border: var(--tag-border-outline-quaternary-subtle, 1px solid var(--hggs-color-primary-default));
        color: var(--tag-color-outline-quaternary-subtle);
      }
    }
  }

  &.hggs-tag--quinary {
    background: var(--tag-background-quinary, var(--hggs-color-quinary-default));
    color: var(--tag-color-quinary, var(--hggs-color-quaternary-default));

    &.hggs-tag--outline {
      border: var(--tag-border-outline-quinary, var(--hggs-border-quinary-default));
      color: var(--tag-color-outline-quinary, var(--hggs-color-quinary-default));
    }

    &.hggs-tag--subtle {
      background: var(--tag-background-subtle-quinary, var(--hggs-color-gray-008-default));
      color: var(--tag-color-subtle-quinary, var(--hggs-color-quaternary-default));

      &.hggs-tag--outline {
        border: var(--tag-border-outline-quinary-subtle, var(--hggs-border-auxiliary-gray-008-default));
        color: var(--tag-color-outline-quinary-subtle, var(--hggs-color-gray-008-default));
      }
    }
  }

  &.hggs-tag--senary {
    background: var(--tag-background-senary, var(--hggs-color-senary-default));
    color: var(--tag-color-senary, var(--hggs-color-quinary-default));

    &.hggs-tag--outline {
      border: var(--tag-border-outline-senary, var(--hggs-border-senary-default));
      color: var(--tag-color-outline-senary, var(--hggs-color-senary-default));
    }

    &.hggs-tag--subtle {
      background: var(--tag-background-subtle-senary, var(--hggs-color-gray-006-default));
      color: var(--tag-color-subtle-senary, var(--hggs-color-quaternary-default));

      &.hggs-tag--outline {
        border: var(--tag-border-outline-senary-subtle, var(--hggs-border-auxiliary-gray-006-default));
        color: var(--tag-color-outline-senary-subtle, var(--hggs-color-gray-006-default));
      }
    }
  }

  &.hggs-tag--rounded {
    border-radius: var(--tag-border-radius, 20px);
  }

  &.hggs-tag--small {
    font-size: var(--tag-font-size-small, var(--hggs-font-size-xs-default));
    padding: var(--tag-padding-small, var(--hggs-space-xs-default) var(--hggs-space-sm-default));
  }
}
