.hggs-step {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  & > .hggs-step-side {
    & > .hggs-step-divider {
      background: var(--step-divider-background, var(--hggs-color-quaternary-default));
      height: var(--step-divider-height, 1px);
      margin: var(--step-divider-margin, 0 0 0 var(--hggs-space-sm-default));
      flex: 1;
      display: none;
    }

    & > .hggs-step-signal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: var(--step-side-font-family, var(--hggs-font-family-default));
      font-size: var(--step-side-font-size, var(--hggs-font-size-md-default));
      font-weight: var(--step-side-font-weight, var(--hggs-font-weight-default));
      color: var(--step-side-color, var(--hggs-color-quinary-default));
      background: var(--step-side-background, var(--hggs-color-quaternary-default));
      width: var(--step-side-size, calc(var(--hggs-space-md-default) / 1.5));
      height: var(--step-side-size, calc(var(--hggs-space-md-default) / 1.5));
      border-radius: var(--step-side-border-radius, 100px);
      margin-right: var(--step-side-margin-right, var(--hggs-space-sm-default));
    }
  }

  & > .hggs-step-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex: 1;

    & > .hggs-step-main {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      margin: var(--step-main-margin, 0);

      & > .hggs-step-title {
        font-family: var(--step-title-font-family, var(--hggs-font-family-default));
        font-size: var(--step-title-font-size, var(--hggs-font-size-default));
        font-weight: var(--step-title-font-weight, var(--hggs-font-weight-default));
        color: var(--step-title-color, var(--hggs-font-color-default));
        margin: var(--step-title-margin, var(--hggs-space-2xs-default) 0 var(--hggs-space-2xs-default) 0);
        width: max-content;
      }

      & > .hggs-step-divider {
        background: var(--step-divider-background, var(--hggs-color-quaternary-default));
        height: var(--step-divider-height, 1px);
        margin: var(--step-divider-margin, 0 0 0 var(--hggs-space-sm-default));
        flex: 1;
        display: none;
      }
    }

    & > .hggs-step-description {
      font-family: var(--step-description-font-family, var(--hggs-font-family-default));
      font-size: var(--step-description-font-size, var(--hggs-font-size-sm-default));
      font-weight: var(--step-description-font-weight, var(--hggs-font-weight-default));
      color: var(--step-description-color, var(--hggs-font-color-002-default));
      width: max-content;
    }
  }

  &.hggs-step--divider {
    & > .hggs-step-body {
      & > .hggs-step-main {
        & > .hggs-step-divider {
          display: block;
        }
      }
    }
  }

  &.hggs-step--complete {
    & > .hggs-step-side {
      & > .hggs-step-signal {
        background: var(--step-side-background-complete, var(--hggs-color-success-default));
      }
    }
  }

  &.hggs-step--small {
    & > .hggs-step-side {
        & > .hggs-step-signal {
          width: var(--step-side-size-small, calc(var(--hggs-space-md-default) / 2));
          height: var(--step-side-size-small, calc(var(--hggs-space-md-default) / 2));
          font-size: var(--step-side-font-size-small, var(--hggs-font-size-sm-default));
        }
    }

    & > .hggs-step-body {
      & > .hggs-step-main {
        & > .hggs-step-title {
          font-size: var(--step-title-font-size-small, var(--hggs-font-size-sm-default));
          margin:
            var(
              --step-title-margin-small,
              calc(var(--hggs-space-md-default) / 8) 0 var(--hggs-space-2xs-default) 0
            );
        }
      }

      & > .hggs-step-description {
        font-size: var(--step-description-font-size-small, var(--hggs-font-size-xs-default));
      }
    }
  }

   &.hggs-step--dot {
    & > .hggs-step-side {
      & > .hggs-step-signal {
        width: var(--step-side-size-dot, calc(var(--hggs-space-md-default) / 3));
        height: var(--step-side-size-dot, calc(var(--hggs-space-md-default) / 3));

        & * {
          display: none;
        }
      }
    }

    & > .hggs-step-body {
      & > .hggs-step-main {
        & > .hggs-step-title {
          font-size: var(--step-title-font-size-dot, var(--hggs-font-size-sm-default));
          margin:
            var(
              --step-title-margin-dot,
              calc(var(--hggs-space-2xs-default) / 4) 0
              calc(var(--hggs-space-2xs-default) / 1.5)
              0
            );
        }
      }

      & > .hggs-step-description {
        font-size: var(--step-description-font-size-dot, var(--hggs-font-size-xs-default));
      }
    }
  }

  &.hggs-step--waiting {
    & > .hggs-step-side {
      & > .hggs-step-signal {
        background: var(--step-side-background-waiting, var(--hggs-color-gray-010-default));
      }
    }

    & > .hggs-step-body {
      & > .hggs-step-main {
        & > .hggs-step-title {
          color: var(--step-title-color-waiting, var(--hggs-font-color-003-default));
        }
      }

      & > .hggs-step-description {
        color: var(--step-description-color-waiting, var(--hggs-font-color-002-default));
      }
    }
  }

  &.hggs-step--error {
    & > .hggs-step-side {
        & > .hggs-step-signal {
          background: var(--step-side-background-error, var(--hggs-color-error-default));
        }
    }

    & > .hggs-step-body {
      & > .hggs-step-main {
        & > .hggs-step-title {
          color: var(--step-title-color-error, var(--hggs-font-color-default));
        }
      }

      & > .hggs-step-description {
        color: var(--step-description-color-error, var(--hggs-font-color-002-default));
      }
    }
  }

  &.hggs-step--complete,
  &.hggs-step--error,
  &.hggs-step--waiting {
    & > .hggs-step-side {
      & > .hggs-step-signal {
        & > .hggs-icon {
          margin: 0;
        }
      }
    }
  }

  &.hggs-step--no-description {
    & > .hggs-step-body {
      & > .hggs-step-main {
        & > .hggs-step-title {
          margin: var(--step-title-margin-small-no-description, 0 0 0 0);
        }
      }

      & > .hggs-step-description {
        display: none;
      }
    }

    &.hggs-step--dot {
      & > .hggs-step-body {
        & > .hggs-step-main {
          & > .hggs-step-title {
            margin: var(--step-title-margin-dot, calc(var(--hggs-space-2xs-default) / 2) 0 0 0);
          }

          & > .hggs-step-description {
            display: none;
          }
        }
      }
    }
  }

  &.hggs-step--vertical {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--step-gap-vertical, var(--hggs-space-sm-default));

    & > .hggs-step-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: var(--step-side-gap-vertical, var(--hggs-space-sm-default));
      height: var(--step-side-height-vertical, 100%);

      & > .hggs-step-signal {
        margin-right: var(--step-signal-margin-right-vertical, none);
      }
    }

    &.hggs-step--divider {
      & > .hggs-step-side {
        & > .hggs-step-divider {
          display: block;
          width: var(--step-divider-width-vertical, 1px);
          height: var(--step-divider-height-vertical, 100px);
          margin: var(--step-divider-margin-vertical, none);
        }
      }
    }

    &.hggs-step--no-description {
      &:not(.hggs-step--divider) {
        align-items: center;
      }

      & > .hggs-step-body {
        & > .hggs-step-main {
          & > .hggs-step-title {
            margin: var(--step-title-margin-vertical-no-description, var(--hggs-space-xs-default) 0 var(--hggs-space-xs-default) 0);
          }
        }
      }
    }

    &.hggs-step--dot {
      & > .hggs-step-body {
        & > .hggs-step-main {
          & > .hggs-step-title {
            margin: var(--step-title-margin-vertical-dot, var(--hggs-space-5xs-default) 0 var(--hggs-space-5xs-default) 0);
          }
        }
      }
    }
  }
}
