.hggs-input {
  width: 100%;
  height: var(--input-height, var(--hggs-space-md-default));
  border: var(--input-border, var(--hggs-border-default));
  border-radius: var(--input-border-radius, var(--hggs-border-radius-default));
  padding: var(--input-padding, var(--hggs-space-sm-default) var(--hggs-space-sm-default));
  font-family: var(--input-font-family, var(--hggs-font-family-default));
  font-size: var(--input-font-size, var(--hggs-font-size-default));
  color: var(--input-color, var(--hggs-color-default));
  box-sizing: border-box;
  background: transparent;

  &.hggs-input--big {
    height: var(--input-height-big, calc(var(--hggs-space-lg-default) / 1.5));
    padding: var(--input-padding-big, var(--hggs-space-sm-default) var(--hggs-space-sm-default));
    font-size: var(--input-font-size-big, var(--hggs-font-size-xl-default));
  }

  &.hggs-input--small {
    height: var(--input-height-small, var(--hggs-space-default));
    padding: var(--input-padding-small, var(--hggs-space-2xs-default) var(--hggs-space-sm-default));
    font-size: var(--input-font-size-small, var(--hggs-font-size-2xs-default));
  }

  &::placeholder {
    font-style: var(--input-placeholder-font-style, normal);
    color: var(--input-placeholder-color, var(--hggs-color-gray-004-default));
  }

  &:focus,
  &.hggs-input--focus {
    outline: var(--input-outline-focus, --hggs-form-outline-focus-default);
    border: var(--input-border-focus, var(--hggs-border-default));
    box-shadow: var(--input-box-shadow-focus, var(--hggs-shadow-default));
  }

  &:disabled,
  &[disabled],
  &.hggs-input--disabled {
    color: var(--input-color-disabled, var(--hggs-color-gray-002-default));
    border: var(--input-border-disabled, var(--hggs-border-disabled-default));
    cursor: not-allowed;
  }

  &:invalid,
  &[error],
  &.hggs-input--error {
    border: var(--input-border-invalid, var(--hggs-border-error-default));
  }
}
