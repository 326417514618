.hggs-steps {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: var(--steps-gap, var(--hggs-space-sm-default));
  list-style-type: none;
  padding-left: 0;

  & .hggs-step {
    align-items: stretch;
    justify-content: space-between;
    flex: 1;

    &:last-child {
      flex: 0;
    }
  }

  &.hggs-steps--vertical {
    flex-direction: column;

    & .hggs-step {
        flex: unset;
        align-items: start;
        justify-content: start;

      &.hggs-step--vertical {
        & .hggs-step-divider {
          flex: unset;
        }
      }
    }
  }
}
