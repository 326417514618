.hggs-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > input + label {
    cursor: pointer;
    text-indent: -9999px;
    width: var(--switch-width, 40px);
    height: var(--switch-height, 20px);
    background: var(--switch-background, transparent);
    display: block;
    border-radius: var(--switch-border-radius, 100px);
    position: relative;
    border: var(--switch-border, var(--hggs-border-quaternary-default));
    box-shadow: var(--switch-box-shadow, unset);

    &::after {
      content: "";
      position: absolute;
      top: var(--switch-circle-top, -1px);
      left: var(--switch-circle-left, 0);
      width: var(--switch-circle-width, 20px);
      height: var(--switch-circle-height, 20px);
      background: var(--switch-circle-background, var(--hggs-color-quaternary-default));
      border: var(--switch-circle-border, --hggs-border-quaternary-default);
      border-radius: var(--switch-circle-border-radius, 90px);
      transition: var(--switch-circle-transition, 0.3s);
      box-shadow: var(--switch-circle-box-shadow, unset);
    }

    &:active::after {
      width: var(--switch-circle-width-active, 40px);
    }
  }

  & > input + label + label {
    font-family: var(--switch-label-font-family, var(--hggs-font-family-default));
    font-size: var(--switch-label-font-size, var(--hggs-font-size-default));
    font-weight: var(--switch-label-font-weight, var(--hggs-font-weight-default));
    font-style: var(--switch-label-font-style, normal);
    color: var(--switch-label-color, var(--hggs-color-default));
    margin-left: var(--switch-margin-left, var(--hggs-space-sm-default));
    cursor: pointer;
    order: 2;
  }

  & input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;

    &:focus + label {
      box-shadow: var(--switch-box-shadow-focus, unset);
    }

    &:checked + label {
      background: var(--switch-background-checked, var(--hggs-color-gray-005-default));

      &::after {
        background: var(--switch-circle-background-checked, var(--hggs-color-quaternary-default));
        border: var(--switch-circle-border-checked, var(--hggs-border-quaternary-default));
        left: var(--switch-circle-left-checked, calc(100% - 0px));
        transform: var(--switch-circle-transform-checked, translateX(-100%));
      }
    }
  }
}
