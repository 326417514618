.hggs-radios {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--radios-gap, var(--hggs-space-xs-default));
}

.hggs-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: var(--radio-size, var(--hggs-space-default));
  position: relative;

  & input[type="radio"] {
    accent-color: var(--radio-accent-color, var(--hggs-color-accent-default));
    position: absolute;
    width: var(--radio-size, var(--hggs-space-default));
    height: var(--radio-size, var(--hggs-space-default));
    border: var(--radio-border, var(--hggs-border-default));
    cursor: pointer;
    opacity: 0;
    order: 1;

    & ~ span {
      width: var(--radio-size, var(--hggs-space-default));
      height: var(--radio-size, var(--hggs-space-default));
    }

    & ~ span::before {
      display: inline-block;
      width: var(--radio-size, var(--hggs-space-default));
      height: var(--radio-size, var(--hggs-space-default));
      content: "";
      background-color: var(--radio-inside-background-color, var(--hggs-color-background-default));
      border: var(--radio-inside-border-bold, var(--hggs-border-default));
      border-radius: 100%;
      box-sizing: border-box;
      transition: var(--radio-inside-transition, box-shadow var(--hggs-transition-ease-default));
    }

    &:hover ~ span::before {
      border: var(--radio-inside-border-hover, var(--hggs-border-default));
    }

    &:focus ~ span::before {
      outline: var(--radio-inside-outline-focus, var(--hggs-form-outline-focus-default));
      box-shadow: var(--radio-inside-box-shadow-focus, var(--hggs-shadow-default));
    }

    &:checked {
      border: var(--radio-border-checked, var(--hggs-border-default));

      & ~ span::before {
        border: var(--radio-inside-border-checked, var(--hggs-border-quaternary-xl-default));
      }

      &:hover ~ span::before {
        border: var(--radio-inside-border-checked-hover, var(--hggs-border-quaternary-xl-default));
      }
    }
  }

  & .hggs-label {
    font-family: var(--radio--label-font-family, var(--hggs-font-family-default));
    font-size: var(--radio--label-font-size, var(--hggs-font-size-default));
    font-weight: var(--radio--label-font-weight, var(--hggs-font-weight-default));
    font-style: var(--radio--label-font-style, normal);
    color: var(--radio--label-color, var(--hggs-color-default));
    margin-left: var(--radio-label-margin-left, var(--hggs-space-sm-default));
    cursor: pointer;
    order: 2;
  }
}
