.hggs-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .hggs-input,
  & .hggs-field,
  & .hggs-fieldset .hggs-field,
  & .hggs-fieldset .hggs-input {
    margin-bottom: var(--form-input-space-vertical, var(--hggs-space-sm-default));
  }

  & .hggs-input:last-child,
  & .hggs-field:last-child,
  & .hgss-fieldset .hggs-input:last-child,
  & .hgss-fieldset .hggs-field:last-child {
    margin-bottom: unset;
  }
}
