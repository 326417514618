.hggs-field {
  width: 100%;

  & > .hggs-label {
    display: block;
    margin-bottom: var(--field-label-margin-bottom, var(--hggs-space-sm-default));
  }

  &.hggs-field--required {
    & > .hggs-label::after {
      content: "*";
      color: var(--field-label-after-color-required, var(--hggs-color-error-default));
      margin-left: var(--field-label-after-margin-left-required, var(--hggs-space-2xs-default));
    }
  }
}
