/* LIGHT MODE */
:root {
  /* COLORS */

  /* Generic colors */
  --hggs-color-white-default: #fff;
  --hggs-color-black-default: #000;

  /* Gray colors */
  --hggs-color-gray-001-default: #f3f3f3;
  --hggs-color-gray-002-default: #f2f2f2;
  --hggs-color-gray-003-default: #e6e6e6;
  --hggs-color-gray-004-default: #d9d9d9;
  --hggs-color-gray-005-default: #ccc;
  --hggs-color-gray-006-default: #bfbfbf;
  --hggs-color-gray-007-default: #b3b3b3;
  --hggs-color-gray-008-default: #a6a6a6;
  --hggs-color-gray-009-default: #999;
  --hggs-color-gray-010-default: #8c8c8c;
  --hggs-color-gray-011-default: #808080;
  --hggs-color-gray-012-default: #737373;
  --hggs-color-gray-013-default: #666;
  --hggs-color-gray-014-default: #595959;
  --hggs-color-gray-015-default: #4d4d4d;
  --hggs-color-gray-016-default: #404040;
  --hggs-color-gray-017-default: #333;
  --hggs-color-gray-018-default: #262626;
  --hggs-color-gray-019-default: #1a1a1a;
  --hggs-color-gray-020-default: #0d0d0d;

  /* Main colors */
  --hggs-color-primary-default: var(--hggs-color-gray-020-default);
  --hggs-color-primary-hover-default: var(--hggs-color-quaternary-default);
  --hggs-color-secondary-default: var(--hggs-color-gray-017-default);
  --hggs-color-secondary-hover-default: var(--hggs-color-primary-default);
  --hggs-color-tertiary-default: var(--hggs-color-gray-014-default);
  --hggs-color-tertiary-hover-default: var(--hggs-color-secondaary-default);
  --hggs-color-quaternary-default: var(--hggs-color-black-default);
  --hggs-color-quaternary-hover-default: var(--hggs-color-quaternary-default);
  --hggs-color-quinary-default: var(--hggs-color-white-default);
  --hggs-color-quinary-hover-default: var(--hggs-color-quinary-default);
  --hggs-color-senary-default: var(--hggs-color-gray-011-default);
  --hggs-color-senary-hover-default: var(--hggs-color-tertiary-default);

  /* Filters colors */
  --hggs-filter-gray-008-default: brightness(0) saturate(100%) invert(8%) sepia(18%) saturate(1214%) hue-rotate(194deg)
    brightness(95%) contrast(91%);
  --hggs-filter-primary-default: brightness(0) saturate(100%) invert(0%) sepia(83%) saturate(3548%) hue-rotate(60deg)
    brightness(84%) contrast(90%);
  --hggs-filter-secondary-default: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(140%) hue-rotate(162deg)
    brightness(98%) contrast(78%);
  --hggs-filter-tertiary-default: brightness(0) saturate(100%) invert(36%) sepia(45%) saturate(0%) hue-rotate(253deg)
    brightness(93%) contrast(113%);
  --hggs-filter-quaternary-default: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(272deg)
    brightness(96%) contrast(108%);
  --hggs-filter-quinary-default: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(127deg)
    brightness(106%) contrast(106%);
  --hggs-filter-senary-default: brightness(0) saturate(100%) invert(57%) sepia(0%) saturate(366%) hue-rotate(176deg)
    brightness(88%) contrast(95%);
  --hggs-filter-default: var(--hggs-filter-quaternary-default);

  /* General colors */
  --hggs-color-default: var(--hggs-color-quaternary-default);
  --hggs-color-accent-default: var(--hggs-color-primary-default);
  --hggs-color-error-default: #8c565a;
  --hggs-color-success-default: #6c7c6c;
  --hggs-color-disabled-default: var(--hggs-color-gray-004-default);
  --hggs-color-background-default: var(--hggs-color-quinary-default);
  --hggs-color-background-hover-default: var(--hggs-color-gray-003-default);

  /* SHADOWS */
  --hggs-shadow-default: 0 1px 5px 0 var(--hggs-color-gray-006-default);
  --hggs-shadow-primary-default: 0 0 7px 1px var(--hggs-color-gray-007-default);

  /* FONT */

  /* Font familes */
  --hggs-font-family-default: sans-serif;

  /* Font colors */
  --hggs-font-color-default: var(--hggs-color-default);
  --hggs-font-color-001-default: var(--hggs-color-gray-004-default);
  --hggs-font-color-002-default: var(--hggs-color-gray-010-default);

  /* Font sizes */
  --hggs-font-size-root-default: clamp(2.89rem, calc(2.77rem + 0.56vw), 3.21rem);
  --hggs-font-size-5xl-default: clamp(2.57rem, calc(2.47rem + 0.5vw), 2.85rem);
  --hggs-font-size-4xl-default: clamp(2.28rem, calc(2.19rem + 0.44vw), 2.53rem);
  --hggs-font-size-3xl-default: clamp(2.03rem, calc(1.95rem + 0.39vw), 2.25rem);
  --hggs-font-size-2xl-default: clamp(1.8rem, calc(1.73rem + 0.35vw), 2rem);
  --hggs-font-size-xl-default: clamp(1.6rem, calc(1.54rem + 0.31vw), 1.78rem);
  --hggs-font-size-lg-default: clamp(1.42rem, calc(1.37rem + 0.27vw), 1.58rem);
  --hggs-font-size-md-default: clamp(1.27rem, calc(1.22rem + 0.24vw), 1.41rem);
  --hggs-font-size-default: clamp(1.13rem, calc(1.08rem + 0.22vw), 1.25rem);
  --hggs-font-size-sm-default: clamp(1rem, calc(0.96rem + 0.19vw), 1.11rem);
  --hggs-font-size-xs-default: clamp(0.89rem, calc(0.85rem + 0.17vw), 0.99rem);
  --hggs-font-size-2xs-default: clamp(0.79rem, calc(0.76rem + 0.15vw), 0.88rem);

  /* Font weights */
  --hggs-font-weight-default: 400;
  --hggs-font-weight-md-default: 500;
  --hggs-font-weight-lg-default: 700;
  --hggs-font-weight-xl-default: 900;

  /* Font letter spacings */
  --hggs-font-letter-spacing-default: 1px;
  --hggs-font-letter-spacing-sm-default: 0.6px;

  /* Font line heights */
  --hggs-line-height-corrector-factor: 8px;
  --hggs-line-height-5xl-default: calc(var(--hggs-font-size-5xl-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-4xl-default: calc(var(--hggs-font-size-4xl-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-3xl-default: calc(var(--hggs-font-size-3xl-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-2xl-default: calc(var(--hggs-font-size-2xl-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-xl-default: calc(var(--hggs-font-size-xl-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-lg-default: calc(var(--hggs-font-size-lg-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-md-default: calc(var(--hggs-font-size-md-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-default: calc(var(--hggs-font-size-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-sm-default: calc(var(--hggs-font-size-sm-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-xs-default: calc(var(--hggs-font-size-xs-default) + var(--hggs-line-height-corrector-factor));
  --hggs-line-height-2xs-default: calc(var(--hggs-font-size-2xs-default) + var(--hggs-line-height-corrector-factor));

  /* BORDERS */
  --hggs-border-radius-md-default: 0;
  --hggs-border-radius-default: 0;
  --hggs-border-size-default: 1px;
  --hggs-border-size-lg-default: 3px;
  --hggs-border-size-xl-default: 8px;
  --hggs-border-type-default: solid;
  --hggs-border-color-default: var(--hggs-color-default);
  --hggs-color-border-primary-default: var(--hggs-color-gray-020-default);
  --hggs-color-border-secondary-default: var(--hggs-color-gray-015-default);
  --hggs-color-border-tertiary-default: var(--hggs-color-gray-010-default);
  --hggs-color-border-quaternary-default: var(--hggs-color-quaternary-default);
  --hggs-color-border-quinary-default: var(--hggs-color-quinary-default);
  --hggs-color-border-senary-default: var(--hggs-color-gray-007-default);
  --hggs-border-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-border-color-default);
  --hggs-border-error-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-error-default);
  --hggs-border-auxiliary-gray-006-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-gray-006-default);
  --hggs-border-auxiliary-gray-008-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-gray-008-default);
  --hggs-border-auxiliary-gray-009-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-gray-009-default);
  --hggs-border-auxiliary-gray-010-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-gray-010-default);
  --hggs-border-auxiliary-gray-012-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-gray-012-default);
  --hggs-border-auxiliary-gray-013-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-gray-013-default);
  --hggs-border-auxiliary-gray-015-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-gray-015-default);
  --hggs-border-primary-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-border-primary-default);
  --hggs-border-primary-big-default: var(--hggs-border-size-lg-default) var(--hggs-border-type-default)
    var(--hggs-color-border-primary-default);
  --hggs-border-secondary-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-border-secondary-default);
  --hggs-border-secondary-gross-default: var(--hggs-border-size-lg-default) var(--hggs-border-type-default)
    var(--hggs-color-border-secondary-default);
  --hggs-border-tertiary-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-border-tertiary-default);
  --hggs-border-quaternary-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-border-quaternary-default);
  --hggs-border-quaternary-xl-default: var(--hggs-border-size-xl-default) var(--hggs-border-type-default)
    var(--hggs-color-border-quaternary-default);
  --hggs-border-quinary-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-border-quinary-default);
  --hggs-border-senary-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-border-senary-default);
  --hggs-border-focus-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-primary-default);
  --hggs-border-disabled-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-gray-005-default);
  --hggs-border-invalid-default: var(--hggs-border-size-default) var(--hggs-border-type-default)
    var(--hggs-color-error-default);

  /* SPACES */
  --hggs-space-default: clamp(0.75rem, 4vw - 1rem, 1.5rem);
  --hggs-space-3xl-default: calc(var(--hggs-space-default) * 12);
  --hggs-space-2xl-default: calc(var(--hggs-space-default) * 8);
  --hggs-space-xl-default: calc(var(--hggs-space-default) * 6);
  --hggs-space-lg-default: calc(var(--hggs-space-default) * 4);
  --hggs-space-md-default: calc(var(--hggs-space-default) * 2);
  --hggs-space-sm-default: calc(var(--hggs-space-default) / 2);
  --hggs-space-xs-default: calc(var(--hggs-space-default) / 4);
  --hggs-space-2xs-default: calc(var(--hggs-space-default) / 6);
  --hggs-space-3xs-default: calc(var(--hggs-space-default) / 8);
  --hggs-space-4xs-default: calc(var(--hggs-space-default) / 10);
  --hggs-space-5xs-default: calc(var(--hggs-space-default) / 12);

  /* LAYERS */
  --hggs-layer-20-default: 20;
  --hggs-layer-10-default: 11;
  --hggs-layer-0-default: 1;

  /* ANIMATIONS */
  --hggs-animation-ease-default: 200ms ease;
  --hggs-animation-ease-all-default: all var(--hggs-animation-ease-default);

  /* TRANSFORMS */
  --hggs-transform-escale-default: scale(1.05);
  --hggs-transform-escale-small-default: scale(1.01);
  --hggs-transform-rotate-translate-default: rotate(-45deg) translate(0%, 0%);

  /* TRANSITIONS */
  --hggs-transition-ease-default: 0.3s ease;

  /* ICONS */
  --hggs-icon-size-micro-default: calc(var(--hggs-icon-size-small-default) / 1.3);
  --hggs-icon-size-small-default: calc(var(--hggs-icon-size-default) / 1.3);
  --hggs-icon-size-default: calc(var(--hggs-space-default) / 1.3);
  --hggs-icon-check-default: url("./default-theme/assets/icons/check.svg");
  --hggs-icon-chevron-down-default: url("./default-theme/assets/icons/chevron-down.svg");
  --hggs-icon-close-default: url("./default-theme/assets/icons/close.svg");
  --hggs-icon-edit-default: url("./default-theme/assets/icons/pencil.svg");
  --hggs-icon-hamburger-menu-default: url("./default-theme/assets/icons/hamburger-menu.svg");
  --hggs-icon-log-out-default: url("./default-theme/assets/icons/log-out.svg");
  --hggs-icon-plus-default: url("./default-theme/assets/icons/plus.svg");
  --hggs-icon-question-default: url("./default-theme/assets/icons/question.svg");
  --hggs-icon-user-default: url("./default-theme/assets/icons/user.svg");
  --hggs-icon-pending-default: url("./default-theme/assets/icons/pending.svg");

  /* LOGO */
  --hggs-logo-default: url("./default-theme/assets/svg/logo.svg");
  --hggs-logo-dark-default: url("./default-theme/assets/svg/logo-dark.svg");
  --hggs-logo-light-default: url("./default-theme/assets/svg/logo-light.svg");
}

/* DARK MODE */
@media (prefers-color-scheme: dark) {
  :root {
    /* COLORS */

    /* Gray Colors */
    --hggs-color-gray-020-default: #f3f3f3;
    --hggs-color-gray-019-default: #f2f2f2;
    --hggs-color-gray-018-default: #e6e6e6;
    --hggs-color-gray-017-default: #d9d9d9;
    --hggs-color-gray-016-default: #ccc;
    --hggs-color-gray-015-default: #bfbfbf;
    --hggs-color-gray-014-default: #b3b3b3;
    --hggs-color-gray-013-default: #a6a6a6;
    --hggs-color-gray-012-default: #999;
    --hggs-color-gray-011-default: #8c8c8c;
    --hggs-color-gray-010-default: #808080;
    --hggs-color-gray-009-default: #737373;
    --hggs-color-gray-008-default: #666;
    --hggs-color-gray-007-default: #595959;
    --hggs-color-gray-006-default: #4d4d4d;
    --hggs-color-gray-005-default: #404040;
    --hggs-color-gray-004-default: #333;
    --hggs-color-gray-003-default: #262626;
    --hggs-color-gray-002-default: #1a1a1a;
    --hggs-color-gray-001-default: #0d0d0d;

    /* Main Colors */
    --hggs-color-primary-default: var(--hggs-color-gray-018-default);
    --hggs-color-primary-hover-default: var(--hggs-color-gray-017-default);
    --hggs-color-secondary-default: var(--hggs-color-gray-016-default);
    --hggs-color-secondary-hover-default: var(--hggs-color-gray-015-default);
    --hggs-color-tertiary-hover-default: var(--hggs-color-gray-013-default);
    --hggs-color-quaternary-default: var(--hggs-color-white-default);
    --hggs-color-quaternary-hover-default: var(--hggs-color-quaternary-default);
    --hggs-color-quinary-default: var(--hggs-color-black-default);
    --hggs-color-quinary-hover-default: var(--hggs-color-quinary-default);
    --hggs-color-senary-default: var(--hggs-color-gray-012-default);
    --hggs-color-senary-hover-default: var(--hggs-color-gray-011-default);

    /* Filters colors */
    --hggs-filter-primary-default: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7439%) hue-rotate(49deg)
      brightness(109%) contrast(91%);
    --hggs-filter-secondary-default: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(0%) hue-rotate(139deg)
      brightness(91%) contrast(91%);
    --hggs-filter-tertiary-default: brightness(0) saturate(100%) invert(73%) sepia(15%) saturate(0%) hue-rotate(157deg)
      brightness(97%) contrast(88%);
    --hggs-filter-senary-default: brightness(0) saturate(100%) invert(67%) sepia(0%) saturate(1354%) hue-rotate(173deg)
      brightness(83%) contrast(85%);
    --hggs-filter-quaternary-default: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%)
      hue-rotate(127deg) brightness(106%) contrast(106%);
    --hggs-filter-quinary-default: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(272deg)
      brightness(96%) contrast(108%);

    /* BORDERS */
    --hggs-border-color-default: var(--hggs-color-quaternary-default);

    /* LOGO */
    --hggs-logo-default: url("./default-theme/assets/svg/logo-dark.svg");
  }
}
