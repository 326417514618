.hggs-list {
  margin-left: var(--list-margin-left, var(--hggs-space-sm-default));
  padding-left: var(--list-padding-left, var(--hggs-space-sm-default));

  & .hggs-list-item,
  & .hggs-list-item a {
    font-family: var(--list-item-font-family, var(--hggs-font-family-default));
    font-weight: var(--list-item-font-weight, var(--hggs-font-weight-default));
    font-size: var(--list-item-font-size, var(--hggs-font-size-default));
    color: var(--list-item-color, var(--hggs-color-default));
    margin-bottom: var(--list-item-margin-bottom, var(--hggs-space-sm-default));
  }

  & .hggs-list-item {
    &::marker {
      content: var(--list-item-content-marker, counter(list-item) ".   ", counter(list-item));
      color: var(--list-item-color-marker, var(--hggs-color-default));
      font-family: var(--list-item-font-family-marker, var(--hggs-font-family-default));
      font-size: var(--list-item-font-size-marker, var(--hggs-font-size-default));
      font-weight: var(--list-item-font-weight-marker, var(--hggs-font-weight-default));
    }
  }
}
