.hggs-link {
  text-decoration: var(--link-text-decoration, underline);
  font-family: var(--link-font-family, var(--hggs-font-family-default));
  font-weight: var(--link-font-weight, var(--hggs-font-weight-default));
  font-size: var(--link-font-size, var(--hggs-font-size-default));
  font-style: var(--link-font-style, italic);
  color: var(--link-color, var(--hggs-color-default));

  &:hover,
  &.hggs-link--hover {
    text-decoration: var(--link-text-decoration-hover, underline);
    color: var(--link-color-hover, var(--hggs-color-default));
    cursor: pointer;
  }
}
